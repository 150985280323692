// Importing necessary components from the react-router-dom library
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Importing Box component from the MUI library
import { Box } from '@mui/material';

// Importing necessary components from the components folder
import { ChannelDetail, VideoDetail, SearchFeed, Navbar, Feed } from './components';

// Defining the App component
const App = () => (
  // Wrapping the component with BrowserRouter component from react-router-dom
  <BrowserRouter>
    {/* Adding a Box component to set background color and font family */}
    <Box sx={{ backgroundColor:  "#031B34", fontFamily:'Manrope'}}>
      {/* Adding the Navbar component */}
      <Navbar />

      {/* Adding Routes component to define routes */}
      <Routes>
        {/* Defining a Route for the home page */}
        <Route exact path='/' element={<Feed />} />

        {/* Defining a Route for a video page */}
        <Route path='/video/:id' element={<VideoDetail />} />

        {/* Defining a Route for a channel page */}
        <Route path='/channel/:id' element={<ChannelDetail />} />

        {/* Defining a Route for a search page */}
        <Route path='/search/:searchTerm' element={<SearchFeed />} />
      </Routes>
    </Box>
  </BrowserRouter>
);

// Exporting the App component
export default App;



{/*This is the main component of a React application that uses React Router to define the routes for different pages of the application. It imports necessary components from the react-router-dom and MUI libraries as well as custom components from the "components" folder.

The component renders a Box component with a background color and font family, a Navbar component, and a Routes component that defines the different routes for the application. There are four different routes defined: the home page ('/'), a video page ('/video/:id'), a channel page ('/channel/:id'), and a search page ('/search/:searchTerm'). Each route is associated with a specific component that will be rendered when the route is visited.

The exact prop is used with the Route for the home page to ensure that it only matches the exact path of '/'. The element prop is used to specify the component that should be rendered when the route is visited. The App component is exported as the default export of the module so that it can be used by other parts of the application.*/}