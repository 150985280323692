// Import the React library and Stack component from the Material-UI library
import React from "react";
import { Stack } from "@mui/material";

// Import the categories data from the constants file
import { categories } from "../utils/constants";

// Define a functional component called Categories that takes in two props
const Categories = ({ selectedCategory, setSelectedCategory }) => (
  // Use the Stack component to render a container for the categories
  <Stack
    // Set the direction of the Stack to "row"
    direction="row"
    // Set the style of the Stack using the Material-UI sx prop
    style={{fontStyle:'Manrope'}}
    sx={{
      // Set the overflow behavior of the Stack to "auto"
      overflowY: "auto",
      // Set the height of the Stack to "auto" on small screens and "95%" on medium screens and above
      height: { sx: "auto", md: "95%" },
      // Set the direction of the Stack to "column" on medium screens and above
      flexDirection: { md: "column" },
      // Add a z-index value to the Categories component
      zIndex: 1,
      fontFamily: 'Manrope',
     
    }}
  >
    {/* Use the map method to loop through the categories array */}
    {categories.map((category) => (
      // Render a button for each category in the array
      <button
        // Set the class name of the button to "category-btn"
        className="category-btn"
        // Set an onClick event handler for the button that sets the selected category using the setSelectedCategory prop
        onClick={() => setSelectedCategory(category.name)}
        // Set the style of the button using the Material-UI style prop
        style={{
          // Set the background color of the button to "#FC1503" if the category name matches the selected category
          background: category.name === selectedCategory && "#FC1503",
          // Set the text color of the button to white
          color: "white",
        }}
        // Set a unique key prop for each button using the category name
        key={category.name}
      >
        {/* Render the category icon and name within the button */}
        <span style={{ color: category.name === selectedCategory ? "white" : "red", marginRight: "15px" }}>
          {category.icon}
        </span>
        <span style={{ opacity: category.name === selectedCategory ? "1" : "0.8" }}>
          {category.name}
        </span>
      </button>
    ))}
  </Stack>
);

// Export the Categories component as the default export of the module
export default Categories;


{/*This component renders a list of categories as buttons using the Material-UI Stack component. It receives two props: selectedCategory and setSelectedCategory which are used to keep track of the currently selected category and update it when a button is clicked. The component also imports an array of category data from a constants file. Each category is rendered as a button with an icon and name. When a button is clicked, the setSelectedCategory prop is called to update the selectedCategory state with the name of the clicked category. The selected category is highlighted with a different background color and opacity than the other categories. The component is exported as the default export of the module.*/}