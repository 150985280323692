// Importing React and MUI components
import React from "react";
import { Stack, Box } from "@mui/material";

// Importing custom components: ChannelCard, Loader, and VideoCard
import { ChannelCard, Loader, VideoCard } from "./";

// Defining Videos functional component and passing props - videos and direction
const Videos = ({ videos, direction }) => {

// If videos is empty or undefined, then show Loader component
if(!videos?.length) return <Loader />;

// Otherwise, return Stack container with videos mapped into VideoCard or ChannelCard inside Box
return (
<Stack
style={{fontStyle:'Manrope'}}
direction={direction || "row"} // Set the direction of Stack container to row (default) or column
flexWrap="wrap" // Set flex-wrap property of Stack container to wrap
justifyContent="center" // Horizontally center the content inside the Stack container
alignItems="center" // Vertically center the content inside the Stack container
gap={2} // Set the gap between items in the Stack container to 2
>
{/* Loop through videos array and create a Box for each item */}
{videos.map((item, idx) => (
<Box key={idx}>
{/* If item has videoId, render VideoCard component */}
{item.id.videoId && <VideoCard video={item} /> }
{/* If item has channelId, render ChannelCard component */}
{item.id.channelId && <ChannelCard channelDetail={item} />}
</Box>
))}
</Stack>
);
}

// Export Videos component as default
export default Videos;

